
.modal-dialog {
 //height: 90%;

  .modal-content{
    //height: 100%;

    .modal-body {

      .modal_close_button {
        cursor: pointer;
        z-index: 2;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 40px;
        color: white;
      }

      .video-js {
        z-index: 1;
      }
    }
  }
}


