
.overlayer {
  //display: none;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left:0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  transition: 1s;
  //pointer-events: none;

  .overlayer_close {
    cursor: pointer;
    z-index: 1002;
    position: absolute;
    right: 50px;
    top: 50px;
    width: 32px;
    height: 30px;
    font-size:  40px;

    img {
      width: 100%;
    }
  }

  .container {
    //border: 1px solid red;

    .row {

      .col {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        .overlayer_image,
        .overlayer_image_b{
          display: none;
          height: 100%;
          //max-width: 100%;

          img, svg {
            height: 90vh;
            margin-top: 5vh;
            //max-width: 100%;
          }

          &.show {
            display: block;
          }
        }

        .overlayer_image_b {
          height: auto;
          width: 80vw;

          img, svg {
            width: 80vw;
            margin-top: 5vh;
            //max-width: 100%;
          }
        }


      }
    }
  }

  &.show {
    //display: block;
    opacity: .9;
    visibility:visible;
    //pointer-events: auto;
  }
}
