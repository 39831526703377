.preloader {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;

  .preloader_container {
    transition: 1000ms;

    .logo {
      display: block;
      width: 150px;
      height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -75px 0 0 -75px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: visible !important;
      -webkit-transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      -webkit-transform-origin: center;
      transform-origin: center;
    }

    @-webkit-keyframes showCircle {
      from {
        stroke-dashoffset: 2512;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
    @keyframes showCircle {
      from {
        /*stroke-dashoffset: 2512;*/
        stroke-dashoffset: 942;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
    @-webkit-keyframes rotateCircle {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotateCircle {
      from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .circle circle {
      fill: transparent;
      stroke-width: 5;
      /*stroke-dasharray: 1256;*/
      stroke-dasharray: 471;
    }
    .circle circle:first-of-type {
      stroke: rgba(0, 138, 214, 0.25);
    }
    .circle circle:nth-of-type(2) {
      stroke: rgba(0, 138, 214, 0.5);
      -webkit-animation: showCircle infinite cubic-bezier(0.39, 0.575, 0.565, 1) 2.2s;
      animation: showCircle infinite cubic-bezier(0.39, 0.575, 0.565, 1) 2.2s;
    }
    .circle circle:nth-of-type(3) {
      stroke: rgba(0, 138, 214, 0.8);
      /*stroke-dashoffset: 1156;*/
      stroke-dashoffset: 433;
      -webkit-animation: rotateCircle infinite linear 1.5s;
      animation: rotateCircle infinite linear 1.5s;
      -webkit-transform-origin: center;
      transform-origin: center;
    }

    &.hide {
      opacity: 0;
    }
  }

  &.hide {
    transition: 1000ms;
    opacity: 0;
  }
}
