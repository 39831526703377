// -----------------------------------------------------------------------------
// Basic styles
// -----------------------------------------------------------------------------

// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_base.scss

html,
body {
  height: 100%;

  * {
    transition: background-color ease-in-out 500ms;
    transition-delay: 0ms;
  }

  a {
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      color: #{var(--secondary-color)};
    }
  }
}
