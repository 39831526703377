.navbar {
  z-index: 1000;
  position: absolute;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  width: 100%;
  padding: 0px;
  transition: 1000ms;
  background-color: white;

  .container-fluid {
    padding-right: 0;
    padding-left: 0;

    .row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;

      .col {
        position: relative;

        .logo {
          z-index: 999;
          position: absolute;
          max-width: 80px;
          width: 70%;
          height: auto;
          margin: 0 auto;
          top: 30px;
          left: 5px;
          opacity: 1;
          transition: opacity 500ms;
          transform-origin: left top;

          img, svg {
            width: 100%;
          }
        }

        #toggle {
          z-index: 10;
          position: absolute;
          top:25px;
          right: 0px;
          height: 50px;
          width: 50px;
          border-radius: 0px;
          cursor: pointer;
          margin-right: 0px;
          transition: 1000ms;
          background-color: white;
          border: 1px solid black;

          @include media-breakpoint-up(sm) {
            //top: 0px;
          }
          @include media-breakpoint-up(md) {
            //top: 25px;
            //right: 50px;
          }
          @include media-breakpoint-up(lg) {
            //top: 25px;
            //right: 75px;
          }
          @include media-breakpoint-up(xl) {
            //top: 25px;
            //right: 75px;
          }

          .burger_holder {
            position: relative;
            width: 30px;
            height: 24px;
            margin-left: 10px;
            margin-top: 12px;
            transition: .5s;

            span{
              background: #DB281B;
              border: none;
              height: 1px;
              width: 30px;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.35s ease;
              cursor: pointer;
              border-radius: 0px;

              &.middle {
                top: 11px;
              }
              &.bottom {
                top: 22px;
              }
            }

            &:hover{

              .top {
                width: 100%;
              }
              .middle {
                width: 80%;
              }
              .bottom {
                width: 60%;
              }
            }
          }

          &.active {

            .burger_holder {

              .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
                //background: red;
              }
              .middle {
                opacity: 0;
                //background: red;
              }
              .bottom {
                transform: translateY(-11px) translateX(0) rotate(-45deg);
                //background: red;
              }

              &:hover{
                transform: rotate(90deg);

                .top {
                  width: 100%;
                }
                .middle {
                  width: 100%;
                }
                .bottom {
                  width: 100%;
                }
              }
            }
          }

          &.show_ {
            opacity: 1;
            top: 30px;

            @include media-breakpoint-up(sm) {
              top: 50px;
            }
            @include media-breakpoint-up(md) {
              top: 50px;
            }
            @include media-breakpoint-up(lg) {
              top: 75px;
            }
            @include media-breakpoint-up(xl) {
              top: 75px;
            }
          }
        }
      }
    }
  }

  &.show {
    opacity: 1;
    top: 0px;
  }

  &.hide {

    .top-nav {

      &.left {
        top: -200px;
      }
      &.right {
        top: -200px;
      }
    }
  }
}

#mainNav {
  position: fixed;
  top: -100%;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, .5);
  opacity: 1;
  visibility: hidden;

  -webkit-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  nav {
    background: white;
    position: absolute;
    top: 0px;
    transform: translateY(0);
    height: auto;
    width: 100%;
    overflow-x: hidden;
    padding-top: 100px;
    padding-bottom: 100px;

    -webkit-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

    -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

    ul {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0 auto;
      position: relative;
      padding-left: 0px !important;

      li {
        display: block;
        line-height: 3rem;
        position: relative;
        opacity: 0;
        padding: 15px 0;
        text-align: center;
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  &.open {
    opacity: 1;
    visibility: visible;
    top: 0px;
    z-index: 3;

    nav {
      top: 0px;

      ul {

        li {
          animation: fadeInTop 0.6s ease forwards;
          opacity: 0;
          animation-delay: .2s;

          &:nth-of-type(2) {
            animation-delay: .25s;
          }
          &:nth-of-type(3) {
            animation-delay: .3s;
          }
          &:nth-of-type(4) {
            animation-delay: .35s;
          }
          &:nth-of-type(5) {
            animation-delay: .35s;
          }
          &:nth-of-type(6) {
            animation-delay: .45s;
          }
        }
      }
    }
  }
  &.closing {

    nav {

      ul {

        li {
          opacity: 1;
          animation: fadeOutTop 0.4s ease forwards;

          &:nth-of-type(6) {
            animation-delay: 0s;
          }

          &:nth-of-type(5) {
            animation-delay: 0.05s;
          }

          &:nth-of-type(4) {
            animation-delay:  0.1s;
          }

          &:nth-of-type(3) {
            animation-delay: 0.15s;
          }

          &:nth-of-type(2) {
            animation-delay: 0.2s;
          }

          &:nth-of-type(1) {
            animation-delay: 0.25s;
          }
        }
      }
    }
  }
  /* stylelint-enable no-descending-specificity */
}


@keyframes fadeInTop {
  0% {
    opacity: 0;
    top: -50px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes fadeOutTop {
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -50px;
  }
}
