/*
* 7-1 Sass Architecture
* https:*sass-guidelin.es/#the-7-1-pattern
* Essential Vendors
* external libraries that should be loaded before
*/
@import '~sass-material-colors';
@import '~normalize-scss';

/*
* Abstracts
* - tools and helpers
*/
@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";

/*
* Vendors
* - CSS from external libraries and frameworks
*/
@import "vendors/fontawesome";
@import "vendors/googlefonts";
@import "vendors/bootstrap";
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";

/**
* Base
* - standard styles for commonly elements
*/
@import "base/base";
@import "base/helpers";
@import "base/fonts";
@import "base/typography";

/*
* Components
* - for small, reusable pieces
*/
@import "components/buttons";

/*
* Layout
* - everything that takes part in laying out the site/application
*/
@import "layout/preloader";
@import "layout/navigation";
@import "layout/header";
@import "layout/modal";
@import "layout/overlayer";

/*
* Pages
* - page-specific styles
*/
@import "pages/home";

/*
* Themes
* - for different aspects
*/
@import "themes/default";
@import "themes/dark";
