/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/src/assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/src/assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/src/assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/src/assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
//src: url("//src/assets/fonts/AkkuratPro-Regular.woff") format("woff");
// <img src="${ require('src/assets/images/sections/section_image_01.svg') }">

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('/src/assets/fonts/montserrat-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/src/assets/fonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/src/assets/fonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/src/assets/fonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/src/assets/fonts/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('/src/assets/fonts/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/src/assets/fonts/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/src/assets/fonts/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/src/assets/fonts/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}


//! set font variables
$font-heading: 'Montserrat', sans-serif;
$font-body: 'Montserrat', sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: $font-heading, serif;
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid inherit;
  padding-right: 10px;

  @include media-breakpoint-up(sm) {
    //font-size: 26px;
    padding-bottom: 50px;

  }
  @include media-breakpoint-up(md) {
    //font-size: 32px;
    padding-bottom: 64px;
  }
  @include media-breakpoint-up(lg) {
    //font-size: 40px;
    padding-bottom: 80px;
  }
  @include media-breakpoint-up(xl) {
    //font-size: 50px;
    padding-bottom: 100px;
  }
}
h2 {
  font-family: $font-heading, serif;
  font-size: 17px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding: 0 10px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
    padding: 0 10px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 15px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0;
    font-size: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }
}
h3 {
  font-family: $font-heading, serif;
  font-size: 12px;
  color: black;
  margin: 0;
  font-weight: 600;
  padding: 0 10px;
  padding-top: 20px;
  //border: 1px solid red;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
    padding: 0 10px;
  }
  @include media-breakpoint-up(md) {
    font-size: 12px;
    padding: 0 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 14px;
    padding: 0;
  }
  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }
}
h4 {
  font-family: $font-heading, serif;
  font-size: 12px;
  color: black;
  margin: 0;
  font-weight: 400;
  padding: 0 10px;
  padding-top: 10px !important;
  //border: 1px solid red;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
    padding: 0 10px;
  }
  @include media-breakpoint-up(md) {
    font-size: 12px;
    padding: 0 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 14px;
    padding: 0;
  }
  @include media-breakpoint-up(xl) {
    font-size: 15px;
  }
}

p, a {
  font-size: 13px;
  font-size: 0.9em;
  font-weight: 400;
  color: white;
  margin: 0;

  &.big {
    font-size: 17px;
    color: $color-04 !important;
  }
}



p {
  padding: 0 10px;

  @include media-breakpoint-up(sm) {

    padding: 0 10px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 15px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0;

  }
}

a.footer {
  padding-bottom: 10px;
  padding-right: 10px;
}

a.header_button {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none ;

  &.smaller {
    font-size: 13px;
  }
}

a.nav-link {
  font-size: 16px;
  line-height: 14px;
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: 300ms;
  padding-left: 5px;
  font-weight: 500;

  &:hover,
  &.active {
    color: #d42821 !important;
    text-decoration: underline !important;
  }
}

.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.margin-bottom_20 {
  margin-bottom: 20px;
}
