#header {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;

  .language_buttons {
    z-index: 200;
    position: fixed;
    top: 0px;
    left: 50%;

    .language_button {
      cursor: pointer;
      display: inline-block;
      width: 50px;
      height: 50px;
      background-color: white;
      margin: 5px;
      text-align: center;
      font-size: 20px;
      line-height: 50px;
      transition: .5s;

      &:hover {
        background-color: #bbb;
      }
    }
  }

  .container-fluid_ {
    min-height: 100vh;
    min-height: 75vh;
    padding-bottom: 100px;
    padding-top: 100px;

    .row {
      min-height: 100vh;
      min-height: 75vh;

      .col {
        min-height: 100vh;
        min-height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header_headline {
          text-align: center;
          width: 100%;
        }

        .header_buttons {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .header_button_wrapper {
          padding: 20px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          row-gap: 20px;
          column-gap: 20px;

          .header_button {
            cursor: pointer;
            width: calc(50% - 10px);
            height: 150px;
            color: white;
            text-align: center;
            font-size: 12px;
            line-height: normal;
            transition: .5s;
            padding: 10px;


            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              transform: scale(1.05);
            }

            span {
              display: inline-block;
              vertical-align: middle;
              line-height: normal;
            }

            @include media-breakpoint-up(sm) {
              font-size: 14px;
            }
            @include media-breakpoint-up(md) {
              width: 45%;
              height: 180px;
              font-size: 16px;
            }
            @include media-breakpoint-up(lg) {
              width: 30%;
              height: 250px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .container-fluid {
    //min-height: 100%;

    .row {
      //border: 1px solid red;

      &.row_top {
        min-height: 50vh;
        padding-top: 100px;
        padding-bottom: 50px;
      }
      &.row_bottom {
        min-height: 50vh;
        padding-bottom: 50px;
      }

      .col {
        padding-right: 0;
        padding-left: 0;
        //border: 1px solid red !important;

        &.col_left {
          background-color: #da2a1c;
          padding-left: 5px;

          //height: 50%;
        }

        &.col_right {
          background: url("/src/assets/images/header/header_bg.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          padding-right: 5px !important;

          min-height: 200px;
        }

        .header_top_content {
          width: 100%;
          height: 100%;
        }

        .container-fluid {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .row {

            .col {
              //padding: 5px;

              &.col_button_01,
              &.col_button_02,
              &.col_button_03,
              &.col_button_04,
              &.col_button_05,
              &.col_button_06{
                padding: 2px 20px;
              }

              @include media-breakpoint-up(sm) {

                &.col_button_01,
                &.col_button_02,
                &.col_button_03,
                &.col_button_04,
                &.col_button_05,
                &.col_button_06{
                  padding: 5px;
                }
              }

              @include media-breakpoint-up(lg) {

                &.col_button_01,
                &.col_button_04 {
                  padding-top: 0;
                  padding-bottom: 10px;
                  padding-right: 5px;
                  padding-left: 0;
                }
                &.col_button_02,
                &.col_button_05 {
                  padding-top: 0;
                  padding-bottom: 10px;
                  padding-right: 5px;
                  padding-left: 5px;
                }
                &.col_button_03,
                &.col_button_06 {
                  padding-top: 0;
                  padding-bottom: 10px;
                  padding-left: 5px;
                  padding-right: 0px;
                }
              }

              .header_button {
                //display: block;
                cursor: pointer;
                width: 100%;
                min-width: 100%;
                height: 150px;
                color: white;
                text-align: center;
                line-height: normal;
                transition: .5s;
                padding: 10px;


                display: flex;
                justify-content: center;
                align-items: center;

                //display: table-cell;
                //vertical-align: middle;

                &:hover {
                  opacity: .8;
                }

                .header_button_text {
                  //width: 100%;
                  //height: 100%;
                  //display: table-cell;
                  //vertical-align: middle;

                  //flex-direction: row;
                }
              }
            }
          }
        }
      }
    }
  }
}

