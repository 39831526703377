// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------

// Colors
/*
$blue: material-color('blue', '500');
$indigo: material-color('deep-purple', 'a400');
$purple: material-color('purple', 'a400');
$pink: material-color('pink', 'a400');
$red: material-color('red', 'a400');
$orange: material-color('orange', 'a400');
$yellow: material-color('yellow', 'a400');
$green: material-color('green', 'a400');
$teal: material-color('teal', 'a400');
$cyan: material-color('cyan', 'a400');
$gray: material-color('grey', '600');
$gray-dark: material-color('grey', '800');

$primary: material-color('yellow', '900') !default;
$secondary: material-color('blue', 'a400') !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: material-color('grey', '50') !default;
$dark: material-color('grey', '900') !default;

$text-color: material-color('grey', '800');
$text-color-contrast: material-color('grey', '50');

// Typography
//
$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 900;

$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;

$headings-font-weight: $font-weight-bolder;
*/

$white: #fff;
$light-grey: #fafafa;
$grey: #d7d7d7;
$black-soft: #444;
$black: #000000;
$blue: #2c638f;
$pink: #fff0f5;
$yellow: #fffacd;

$color-01: #009CDE;
$color-02: #003C71;
$color-03: #5b6770;
$color-04: #43695B;
$color-05: #5B7F95;
$color-06: #94450B;
$color-07: #7F9C90;
$color-08: #F7F7F7;

$red: #d42821;
$color-border-01: #035f91;
$color-border-02: #011c34;
$color-border-03: #162d28;
$color-border-04: #294050;
$color-border-05: #561d01;
$color-border-06: #142b25;

.color-black {
  color: $black;
}
.color-red {
  color: $red;
}
.color-01 {
  color: $color-01 !important;
}
.color-02 {
  color: $color-02 !important;
}
.color-03 {
  color: $color-03 !important;
}
.color-04 {
  color: $color-04 !important;
}
.color-05 {
  color: $color-05 !important;
}
.color-06 {
  color: $color-06 !important;
}
.color-07 {
  color: $color-07 !important;
}

.background-color-01 {
  background-color: $color-01;
}
.background-color-02 {
  background-color: $color-02;
}
.background-color-03 {
  background-color: $color-03;
}
.background-color-04 {
  background-color: $color-04;
}
.background-color-05 {
  background-color: $color-05;
}
.background-color-06 {
  background-color: $color-06;
}
.background-color-07 {
  background-color: $color-07;
}
.background-color-08 {
  background-color: $color-08;
}


$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: "~slick-carousel/slick/";
