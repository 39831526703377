// -----------------------------------------------------------------------------
// Dark theme
// -----------------------------------------------------------------------------
html[data-theme='dark'] {
  --primary-color: #{$gray-900};
  --primary-color-rgb: #{toRGB($gray-900, true)};
  --secondary-color: #{$yellow};
  --secondary-color-rgb: #{toRGB($yellow, true)};
  --bg-color: #{$gray-800};
  --bg-color-rgb: #{toRGB($gray-800, true)};
  --text-color: #{a11y-color($white, $gray-800)};
  --text-color-rgb: #{toRGB(a11y-color($white, $gray-800,'AAA'), true)};
  --text-color-contrast: #{a11y-color($white, $gray-800,'AAA')};
  --text-color-contrast-rgb: #{toRGB(a11y-color($white, $gray-800,'AAA'), true)};

  body {
    color: var(--text-color);
    background: var(--bg-color);
  }
}
